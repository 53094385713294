var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.erreur, warning: _vm.warning } },
    [
      _c("transition", { attrs: { name: "slide" } }, [
        _c("div", { staticClass: "app flex-row align-items-center" }, [
          _c("div", { staticClass: "scattered-previews" }, [
            _c("div", {
              staticClass: "glasses",
              staticStyle: {
                width: "60px",
                height: "60px",
                top: "10px",
                left: "10px",
                transform: "rotate(0.805916turn)",
                opacity: "0.5",
              },
            }),
            _c("div", {
              staticClass: "glasses",
              staticStyle: {
                width: "60px",
                height: "60px",
                top: "240px",
                left: "80px",
                transform: "rotate(0.805916turn)",
                opacity: "0.5",
              },
            }),
            _c("div", {
              staticClass: "glasses",
              staticStyle: {
                width: "60px",
                height: "60px",
                top: "420px",
                left: "55px",
                transform: "rotate(0.805916turn)",
                opacity: "0.5",
              },
            }),
            _c("div", {
              staticClass: "glasses",
              staticStyle: {
                width: "60px",
                height: "60px",
                top: "620px",
                left: "80px",
                transform: "rotate(0.805916turn)",
                opacity: "0.5",
              },
            }),
            _c("div", {
              staticClass: "glasses",
              staticStyle: {
                width: "60px",
                height: "60px",
                top: "350px",
                left: "250px",
                transform: "rotate(0.805916turn)",
                opacity: "0.5",
              },
            }),
            _c("div", {
              staticClass: "glasses",
              staticStyle: {
                width: "60px",
                height: "60px",
                top: "550px",
                left: "10px",
                transform: "rotate(0.805916turn)",
                opacity: "0.5",
              },
            }),
            _c("div", {
              staticClass: "glasses",
              staticStyle: {
                width: "60px",
                height: "60px",
                top: "750px",
                left: "10px",
                transform: "rotate(0.805916turn)",
                opacity: "0.5",
              },
            }),
            _c("div", {
              staticClass: "glasses",
              staticStyle: {
                width: "60px",
                height: "60px",
                top: "750px",
                left: "250px",
                transform: "rotate(0.805916turn)",
                opacity: "0.5",
              },
            }),
            _c("div", {
              staticClass: "glasses",
              staticStyle: {
                width: "75px",
                height: "96px",
                top: "10px",
                left: "199px",
                transform: "rotate(0.805916turn)",
                opacity: "0.22201",
              },
            }),
            _c("div", {
              staticClass: "glasses",
              staticStyle: {
                width: "250px",
                height: "150px",
                top: "10px",
                left: "799px",
                transform: "rotate(0.613116turn)",
                opacity: "0.22201",
              },
            }),
            _c("div", {
              staticClass: "glasses",
              staticStyle: {
                width: "85px",
                height: "96px",
                top: "110px",
                left: "210px",
                transform: "rotate(0.105916turn)",
                opacity: "0.32745",
              },
            }),
            _c("div", {
              staticClass: "glasses",
              staticStyle: {
                width: "100px",
                height: "120px",
                top: "226px",
                left: "350px",
                transform: "rotate(0.105916turn)",
                opacity: "0.32745",
              },
            }),
            _c("div", {
              staticClass: "glasses",
              staticStyle: {
                width: "70px",
                height: "88px",
                top: "580px",
                left: "1360px",
                transform: "rotate(0.376607turn)",
                opacity: "0.32745",
              },
            }),
            _c("div", {
              staticClass: "glasses",
              staticStyle: {
                width: "80px",
                height: "100px",
                top: "520px",
                left: "260px",
                transform: "rotate(0.115907turn)",
                opacity: "0.32745",
              },
            }),
            _c("div", {
              staticClass: "glasses",
              staticStyle: {
                width: "155px",
                height: "165px",
                top: "241px",
                left: "1492px",
                transform: "rotate(0.420648turn)",
                opacity: "0.319688",
              },
            }),
            _c("div", {
              staticClass: "glasses",
              staticStyle: {
                width: "85px",
                height: "70px",
                top: "470px",
                left: "499px",
                transform: "rotate(0.555818turn)",
                opacity: "0.32745",
              },
            }),
            _c("div", {
              staticClass: "glasses",
              staticStyle: {
                width: "120px",
                height: "210px",
                top: "703px",
                left: "630px",
                transform: "rotate(0.115907turn)",
                opacity: "0.32745",
              },
            }),
            _c("div", {
              staticClass: "glasses",
              staticStyle: {
                width: "90px",
                height: "150px",
                top: "703px",
                left: "1123px",
                transform: "rotate(0.915907turn)",
                opacity: "0.32745",
              },
            }),
            _c("div", {
              staticClass: "glasses",
              staticStyle: {
                width: "155px",
                height: "165px",
                top: "703px",
                left: "1600px",
                transform: "rotate(0.420648turn)",
                opacity: "0.319688",
              },
            }),
          ]),
          _c(
            "div",
            { staticClass: "container py-5" },
            [
              _c(
                "b-row",
                [
                  _c("b-col", { staticClass: "clearfix" }, [
                    _c(
                      "h1",
                      {
                        staticClass:
                          "display-1 mr-4 text-center text-grey-dark",
                      },
                      [_vm._v("404")]
                    ),
                    _c(
                      "h1",
                      {
                        staticClass: "text-center text-grey-dark",
                        class:
                          _vm.$screen.width > 720 ? "display-4" : "display-6",
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(3, "Oops! Page not found")) +
                            ".\n\t\t\t\t\t\t"
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "d-flex justify-content-center mt-3" },
                      [
                        _c(
                          "h5",
                          {
                            class:
                              _vm.$screen.width > 720
                                ? "display-6 w-50"
                                : "small",
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t\t" +
                                _vm._s(
                                  _vm.FormMSG(
                                    5,
                                    "The page you are looking for is not available"
                                  )
                                ) +
                                "."
                            ),
                            _c("br"),
                            _vm._v(
                              "\n\t\t\t\t\t\t\t\t" +
                                _vm._s(
                                  _vm.FormMSG(
                                    7,
                                    "the link you followed may be broken or the page may have been removed"
                                  )
                                ) +
                                "\n\t\t\t\t\t\t\t"
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "justify-content-center" },
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-button",
                        {
                          staticClass:
                            "text-success d-flex justify-content-center align-items-center",
                          staticStyle: { gap: "10px" },
                          attrs: { size: "lg", variant: "link", block: "" },
                          on: { click: _vm.redirect },
                        },
                        [
                          _c(_vm.getLucideIcon("ArrowLeft"), {
                            tag: "component",
                            attrs: { size: 20, "stroke-width": 1.5 },
                          }),
                          _vm._v(
                            "\n\t\t\t\t\t\t\t" +
                              _vm._s(_vm.FormMSG(6, "Go back")) +
                              "\n\t\t\t\t\t\t"
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
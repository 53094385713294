<template>
	<errorContainer :error="erreur" :warning="warning">
		<transition name="slide">
			<div class="app flex-row align-items-center">
				<div class="scattered-previews">
					<div class="glasses" style="width: 60px; height: 60px; top: 10px; left: 10px; transform: rotate(0.805916turn); opacity: 0.5"></div>
					<div class="glasses" style="width: 60px; height: 60px; top: 240px; left: 80px; transform: rotate(0.805916turn); opacity: 0.5"></div>
					<div class="glasses" style="width: 60px; height: 60px; top: 420px; left: 55px; transform: rotate(0.805916turn); opacity: 0.5"></div>
					<div class="glasses" style="width: 60px; height: 60px; top: 620px; left: 80px; transform: rotate(0.805916turn); opacity: 0.5"></div>
					<div class="glasses" style="width: 60px; height: 60px; top: 350px; left: 250px; transform: rotate(0.805916turn); opacity: 0.5"></div>
					<div class="glasses" style="width: 60px; height: 60px; top: 550px; left: 10px; transform: rotate(0.805916turn); opacity: 0.5"></div>
					<div class="glasses" style="width: 60px; height: 60px; top: 750px; left: 10px; transform: rotate(0.805916turn); opacity: 0.5"></div>
					<div class="glasses" style="width: 60px; height: 60px; top: 750px; left: 250px; transform: rotate(0.805916turn); opacity: 0.5"></div>
					<div class="glasses" style="width: 75px; height: 96px; top: 10px; left: 199px; transform: rotate(0.805916turn); opacity: 0.22201"></div>
					<div class="glasses" style="width: 250px; height: 150px; top: 10px; left: 799px; transform: rotate(0.613116turn); opacity: 0.22201"></div>
					<div class="glasses" style="width: 85px; height: 96px; top: 110px; left: 210px; transform: rotate(0.105916turn); opacity: 0.32745"></div>
					<div class="glasses" style="width: 100px; height: 120px; top: 226px; left: 350px; transform: rotate(0.105916turn); opacity: 0.32745"></div>
					<div class="glasses" style="width: 70px; height: 88px; top: 580px; left: 1360px; transform: rotate(0.376607turn); opacity: 0.32745"></div>
					<div class="glasses" style="width: 80px; height: 100px; top: 520px; left: 260px; transform: rotate(0.115907turn); opacity: 0.32745"></div>
					<div
						class="glasses"
						style="width: 155px; height: 165px; top: 241px; left: 1492px; transform: rotate(0.420648turn); opacity: 0.319688"
					></div>
					<div class="glasses" style="width: 85px; height: 70px; top: 470px; left: 499px; transform: rotate(0.555818turn); opacity: 0.32745"></div>
					<div class="glasses" style="width: 120px; height: 210px; top: 703px; left: 630px; transform: rotate(0.115907turn); opacity: 0.32745"></div>
					<div class="glasses" style="width: 90px; height: 150px; top: 703px; left: 1123px; transform: rotate(0.915907turn); opacity: 0.32745"></div>
					<div
						class="glasses"
						style="width: 155px; height: 165px; top: 703px; left: 1600px; transform: rotate(0.420648turn); opacity: 0.319688"
					></div>
				</div>
				<div class="container py-5">
					<b-row>
						<b-col class="clearfix">
							<h1 class="display-1 mr-4 text-center text-grey-dark">404</h1>
							<h1 class="text-center text-grey-dark" :class="$screen.width > 720 ? 'display-4' : 'display-6'">
								{{ FormMSG(3, 'Oops! Page not found') }}.
							</h1>
							<div class="d-flex justify-content-center mt-3">
								<h5 :class="$screen.width > 720 ? 'display-6 w-50' : 'small'">
									{{ FormMSG(5, 'The page you are looking for is not available') }}.<br />
									{{ FormMSG(7, 'the link you followed may be broken or the page may have been removed') }}
								</h5>
							</div>
						</b-col>
					</b-row>
					<b-row class="justify-content-center">
						<b-col>
							<b-button
								size="lg"
								variant="link"
								class="text-success d-flex justify-content-center align-items-center"
								block
								@click="redirect"
								style="gap: 10px"
							>
								<component :is="getLucideIcon('ArrowLeft')" :size="20" :stroke-width="1.5" />
								{{ FormMSG(6, 'Go back') }}
							</b-button>
						</b-col>
					</b-row>
				</div>
			</div>
		</transition>
	</errorContainer>
</template>

<script>
import globalMixin from '@/mixins/global.mixin';
import languageMessages from '@/mixins/languageMessages';

export default {
	name: 'Page404',

	mixins: [languageMessages, globalMixin],

	data() {
		return {
			erreur: {},
			warning: ''
		};
	},

	methods: {
		redirect() {
			this.$router.go(-1);
		}
	}
};
</script>

<style lang="scss" scoped>
.glasses {
	/* From https://css.glass */
	background: rgba(255, 255, 255, 0.2);
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(5px);
	-webkit-backdrop-filter: blur(5px);
	border: 1px solid rgba(255, 255, 255, 0.3);
	position: absolute;
}
.scattered-previews {
	position: absolute;
	width: 100%;
	height: 100vh;
	overflow: hidden;
}
</style>
